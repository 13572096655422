import Fuse from "fuse.js";
import { DocumentColorEnum, highlightColors } from "./pdfColors";
// import { DocumentColorEnum, highlightColors } from "./colors";

const HIGHLIGHT_CLASSNAME = "opacity-40 saturate-[3] highlighted-by-llama ";

const DIRECTION = {
  START: 0,
  END: 1,
};
// const DIRECTION = {
//   START: 'START',
//   END: 'END',
// };

// var Fuse = require("fuse.js");
// var DocumentColorEnum = require("./colors").DocumentColorEnum;
// var highlightColors = require("./colors").highlightColors;

// export function multiHighlight(textToHighlight, pageNumber, color) {
//   if (color === 0) {
//     color = DocumentColorEnum.yellow;
//   }
//   var highlightColor = highlightColors[color];
//   // var spans = document.querySelectorAll("div[data-page-number='" + pageNumber + "'] .react-pdf__Page__textContent.textLayer span");
//   const spans = document.querySelectorAll(`div[data-page-number='${pageNumber + 1}'] .react-pdf__Page__textContent.textLayer span`);

//   var words = [];
//   spans.forEach(function (span, spanIdx) {
//     var htmlSpan = span;
//     var spanWords = htmlSpan.textContent || "";

//     spanWords.split(" ").map(function (text, wordIdx) {
//       words.push({ text: text, spanIdx: spanIdx, wordIdx: wordIdx });
//     });
//   });

//   var searchString = textToHighlight?.replace(/\s{2,}/g, " ")
//     .replace(/\t/g, " ")
//     .trim()
//     .replace(/(\r\n|\n|\r)/g, "");

//   var searchWords = searchString?.split(" ");
//   var lenSearchString = searchWords?.length;
//   if (!lenSearchString) {
//     return;
//   }

//   var firstWord = searchWords[0];
//   if (!firstWord) {
//     return;
//   }

//   var searchData = generateDirectSearchData(firstWord, words, lenSearchString);

//   var options = {
//     includeScore: true,
//     threshold: 0.1,
//     minMatchCharLength: 10,
//     shouldSort: true,
//     findAllMatches: true,
//     includeMatches: true,
//     keys: ["text"],
//   };

//   var fuse = new Fuse(searchData, options);
//   var result = fuse.search(searchString);

//   if (result.length > 0) {
//     var searchResult = result[0].item;

//     var startSpan = searchResult.startSpan || 0;
//     var endSpan = searchResult.endSpan || 0;
//     var startWordIdx = searchResult.startWordIdx || 0;
//     var endWordIdx = searchResult.endWordIdx || 0;

//     for (let i = startSpan; i < endSpan + 1; i++) {
//       const spanToHighlight = spans[i];
//       if (i === startSpan) {
//         if (startWordIdx === 0) {
//           highlightHtmlElement(spanToHighlight, highlightColor);
//         } else {
//           partialHighlight(startWordIdx, spanToHighlight, DIRECTION.START);
//         }
//       } else if (i === endSpan) {
//         if (endWordIdx === 0) {
//           return;
//         } else {
//           partialHighlight(endWordIdx, spanToHighlight, DIRECTION.END);
//         }
//       } else {
//         highlightHtmlElement(spanToHighlight, highlightColor);
//       }
//     }
//   }
//   return true;
// }

// export function highlightHtmlElement(div, color) {
//   var text = div.textContent || "";
//   var newSpan = document.createElement("span");

//   newSpan.className = HIGHLIGHT_CLASSNAME + color;
//   newSpan.innerText = text;
//   div.innerText = "";
//   div.appendChild(newSpan);
// }

// function partialHighlight(idx, span, direction) {
//   if (direction === 0) {
//     direction = DIRECTION.START;
//   }
//   var text = span.textContent;
//   if (!text) {
//     return;
//   }
//   var test = text.split(" ")[idx - 1] || "";
//   var substringToHighlight = test;

//   span.textContent = "";

//   var parts = text.split(substringToHighlight);

//   parts.forEach(function (part, index) {
//     if (direction === DIRECTION.START) {
//       if (index === 0) {
//         span.appendChild(document.createTextNode(part));
//       } else {
//         span.appendChild(document.createTextNode(test));
//         var highlightSpan = document.createElement("span");
//         highlightSpan.className = HIGHLIGHT_CLASSNAME;
//         highlightSpan.textContent = part;
//         span.appendChild(highlightSpan);
//       }
//     }

//     if (direction === DIRECTION.END) {
//       if (index === 0) {
//         highlightSpan = document.createElement("span");
//         highlightSpan.className = HIGHLIGHT_CLASSNAME;
//         highlightSpan.textContent = part;
//         span.appendChild(highlightSpan);
//         span.appendChild(document.createTextNode(part));
//       } else {
//         span.appendChild(document.createTextNode(test));
//         span.appendChild(document.createTextNode(part));
//       }
//     }
//   });
// }

// function generateFuzzySearchData(arr, n) {
//   var searchStrings = [];

//   for (var i = 0; i <= arr.length - n; i++) {
//     var text = arr.slice(i, i + n).reduce(function (acc, val) {
//       return acc + " " + val.text;
//     }, "");

//     var startSpan = arr[i].spanIdx || 0;
//     var endSpan = (arr[i + n - 1] && arr[i + n - 1].spanIdx) || 0;
//     var startWordIdx = arr[i].wordIdx || 0;
//     var endWordIdx = (arr[i + n - 1] && arr[i + n - 1].wordIdx) || 0;
//     searchStrings.push({ text: text, startSpan: startSpan, endSpan: endSpan, startWordIdx: startWordIdx, endWordIdx: endWordIdx });
//   }

//   return searchStrings;
// }

// function generateDirectSearchData(startString, words, n) {
//   var searchStrings = [];

//   for (var i = 0; i <= words.length - n; i++) {
//     if (words[i].text === startString) {
//       const text = words.slice(i, i + n).reduce((acc, val) => acc + " " + val.text, "");

//       const startSpan = words[i]?.spanIdx || 0; // have to add these defaults because typescript is dumb
//       const endSpan = words[i + n]?.spanIdx || 0;
//       const startWordIdx = words[i]?.wordIdx || 0;
//       const endWordIdx = words[i + n]?.wordIdx || 0;

//       searchStrings.push({ text: text, startSpan: startSpan, endSpan: endSpan, startWordIdx: startWordIdx, endWordIdx: endWordIdx });
//     }
//   }

//   return searchStrings;
// }

export function scrollToHighlight(pageNumber) {
  // if (hasScrolled.current) return; // Prevent multiple scrolls
  const element = document.querySelector(`div[data-page-number='${pageNumber + 1}']`);
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
    console.log("Scrolled to page number:", pageNumber + 1);
    // hasScrolled.current = true; // Mark as scrolled
  } else {
    console.log("Element not found for page number:", pageNumber + 1);
  }
}

export function multiHighlight(textToHighlight, pageNumber, color) {
  let highlightApplied = false; // Flag to track if highlight is applied

  if (color === 0) {
    color = DocumentColorEnum.yellow;
  }
  var highlightColor = highlightColors[color];
  const spans = document.querySelectorAll(`div[data-page-number='${pageNumber + 1}'] .react-pdf__Page__textContent.textLayer span`);

  console.log("Text to highlight:", textToHighlight);
  console.log("Number of spans found:", spans.length);

  function attemptHighlight(searchString) {
    // console.log("Attempting to highlight with search string:", searchString);

    const words = [];
    spans.forEach((span, spanIdx) => {
      const spanWords = (span.textContent || "").split(" ");
      spanWords.forEach((text, wordIdx) => {
        words.push({ text, spanIdx, wordIdx });
      });
    });

    console.log("Words extracted from spans:", words);

    const searchWords = searchString?.split(" ");
    const lenSearchString = searchWords?.length;
    if (!lenSearchString) {
      console.log("Search string is empty after split.");
      return false;
    }

    const insignificantWords = new Set(["a", "an", "the", "of", "in", "on", "at", "to", "is", "it"]);

    function getSignificantStartingWord(searchWords) {
      for (let i = 0; i < searchWords.length; i++) {
        const word = searchWords[i];
        if (word.length >= 4 && !insignificantWords.has(word.toLowerCase())) {
          return word;
        }
      }
      return null;
    }

    const firstWord = getSignificantStartingWord(searchWords);
    if (!firstWord) {
      console.log("No significant first word found.");
      return false;
    }

    const searchData = generateDirectSearchData(firstWord, words, lenSearchString);
    console.log("Search data generated for Fuse.js:", searchData);

    const options = {
      includeScore: true,
      threshold: 0.3, // Adjusted threshold
      minMatchCharLength: 12,
      shouldSort: true,
      findAllMatches: true,
      includeMatches: true,
      keys: ["text"],
    };

    const fuse = new Fuse(searchData, options);
    const result = fuse.search(searchString);
    console.log("Fuse.js search result:", result);

    if (result.length > 0) {
      const searchResult = result[0].item;

      const startSpan = searchResult.startSpan || 0;
      const endSpan = searchResult.endSpan || 0;
      const startWordIdx = searchResult.startWordIdx || 0;
      const endWordIdx = searchResult.endWordIdx || 0;

      console.log("Highlighting spans from", startSpan, "to", endSpan);
      // scrollToHighlight(pageNumber); // Scroll to the highlighted page

      for (let i = startSpan; i <= endSpan; i++) {
        const spanToHighlight = spans[i];
        if (i === startSpan) {
          if (startWordIdx === 0) {
            console.log("Highlighting entire span at index:", i);
            highlightHtmlElement(spanToHighlight, highlightColor);
            highlightApplied = true;
          } else {
            console.log("Partially highlighting span at index:", i, "from startWordIdx:", startWordIdx);
            partialHighlight(startWordIdx, spanToHighlight, DIRECTION.START);
            highlightApplied = true;
          }
        } else if (i === endSpan) {
          if (endWordIdx === 0) {
            console.log("End word index is 0, returning.");
            return highlightApplied;
          } else {
            console.log("Partially highlighting span at index:", i, "to endWordIdx:", endWordIdx);
            partialHighlight(endWordIdx, spanToHighlight, DIRECTION.END);
            highlightApplied = true;
          }
        } else {
          console.log("Highlighting entire span at index:", i);
          highlightHtmlElement(spanToHighlight, highlightColor);
          highlightApplied = true;
        }
      }
    } else {
      console.log("No match found with Fuse.js.");
    }
    return highlightApplied;
  }

  // Clean and prepare the search string
  const searchString = textToHighlight
    ?.replace(/\s{2,}/g, " ")
    .replace(/\t/g, " ")
    .trim()
    .replace(/(\r\n|\n|\r)/g, "");

  console.log("Cleaned search string:", searchString);

  // Attempt initial highlight
  if (attemptHighlight(searchString)) {
    console.log("Initial highlight successful.");
    // scrollToHighlight(pageNumber); // Scroll to the highlighted page only if highlighting is successful
    console.log("we will return true now");
    return true;
  }

  console.log("Initial highlight failed.");

  return false;
}

export function highlightHtmlElement(div, color) {
  const text = div.textContent || "";
  const newSpan = document.createElement("span");

  newSpan.className = HIGHLIGHT_CLASSNAME + color;
  newSpan.innerText = text;
  div.innerText = "";
  div.appendChild(newSpan);
}

function partialHighlight(idx, span, direction) {
  if (direction === 0) {
    direction = DIRECTION.START;
  }
  const text = span.textContent;
  if (!text) {
    return;
  }
  const test = text.split(" ")[idx - 1] || "";
  const substringToHighlight = test;

  span.textContent = "";

  const parts = text.split(substringToHighlight);

  parts.forEach((part, index) => {
    if (direction === DIRECTION.START) {
      if (index === 0) {
        span.appendChild(document.createTextNode(part));
      } else {
        span.appendChild(document.createTextNode(test));
        const highlightSpan = document.createElement("span");
        highlightSpan.className = HIGHLIGHT_CLASSNAME;
        highlightSpan.textContent = part;
        span.appendChild(highlightSpan);
      }
    }

    if (direction === DIRECTION.END) {
      if (index === 0) {
        const highlightSpan = document.createElement("span");
        highlightSpan.className = HIGHLIGHT_CLASSNAME;
        highlightSpan.textContent = part;
        span.appendChild(highlightSpan);
        span.appendChild(document.createTextNode(part));
      } else {
        span.appendChild(document.createTextNode(test));
        span.appendChild(document.createTextNode(part));
      }
    }
  });
}

function generateDirectSearchData(startString, words, n) {
  const searchStrings = [];

  for (let i = 0; i <= words.length - n; i++) {
    if (words[i].text === startString) {
      const text = words.slice(i, i + n).reduce((acc, val) => acc + " " + val.text, "");

      const startSpan = words[i]?.spanIdx || 0;
      const endSpan = words[i + n - 1]?.spanIdx || 0;
      const startWordIdx = words[i]?.wordIdx || 0;
      const endWordIdx = words[i + n - 1]?.wordIdx || 0;

      searchStrings.push({ text: text.trim(), startSpan: startSpan, endSpan: endSpan, startWordIdx: startWordIdx, endWordIdx: endWordIdx });
    }
  }

  console.log("Generated search data:", searchStrings);
  return searchStrings;
}
