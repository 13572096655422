import React, { useState } from "react";
import { AppBar, Toolbar, CssBaseline, IconButton, Menu, Box, Button, MenuItem, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu"; // For the hamburger icon

import logoImage from "../../../assets/landingpage/logo.png"; // Make sure the path to your logo is correct
import { colors } from "../../../utils/colors";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: "center", // Changed to center the toolbar content
  padding: theme.spacing(2),
  backgroundColor: "transparent", // Removed AppBar background color
}));

const StyledButton = styled(Button)({
  color: "#4caf50",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "transparent",
    color: colors.LGREEN,
  },
  borderRadius: "5px", // Adjust to match the desired border radius from the image
  padding: "10px 30px", // Adjust padding to match the image
  textTransform: "none",
  margin: "0 10px",
  fontWeight: 700,
});

const Logo = styled("img")({
  height: "25px",
  marginRight: "10px",
  position: "absolute", // Positioning logo absolutely to the left
  left: 20, // Adjust this value as needed for proper positioning
});

const Header = ({ onSectionOneClick, onSectionTwoClick, onSectionThreeClick }) => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickSignin = () => {
    if (isAuthenticated) {
      navigate("/data-import");
    } else {
      navigate("/invitation");
    }
  };

  return (
    <AppBar position="static" elevation={0} sx={{ backgroundColor: "transparent" }}>
      <CssBaseline />
      <StyledToolbar>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Logo src={logoImage} alt="DueDil" />
          {isMobile && (
            <>
              <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ ml: 15 }} onClick={handleMenu}>
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openMenu}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    onSectionOneClick();
                  }}
                >
                  Features
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    onSectionTwoClick();
                  }}
                >
                  Ai-Powered
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    onSectionThreeClick();
                  }}
                >
                  Use Cases
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
        {!isMobile && (
          <Box sx={{ display: "flex", justifyContent: "center", flexGrow: 1 }}>
            <StyledButton onClick={onSectionOneClick} style={{ backgroundColor: "transparent", color: "white" }}>
              Features
            </StyledButton>
            <StyledButton onClick={onSectionTwoClick} style={{ backgroundColor: "transparent", color: "white" }}>
              Ai-Powered
            </StyledButton>
            <StyledButton onClick={onSectionThreeClick} style={{ backgroundColor: "transparent", color: "white" }}>
              Use Cases
            </StyledButton>
          </Box>
        )}
        <StyledButton onClick={handleClickSignin} variant="contained" sx={{ backgroundColor: colors.LGREEN, color: "white" }}>
          {isAuthenticated ? "My account" : "Sign in"}
        </StyledButton>
      </StyledToolbar>
    </AppBar>
  );
};

export default Header;
