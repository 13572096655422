import React, { useEffect, useState } from "react";
import usePDFViewer from "./usePdfViewer";

import PDFOptionsBar from "./PdfOptionsBar";
import { Grid, Autocomplete, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import MemoizedVirtualizedPDF from "./VirtualizedPdf";
import { useDispatch, useSelector } from "react-redux";
import { windowWidth } from "../../utils/utils";
import * as pdfjsLib from "pdfjs-dist/webpack";
import { setActivePdf, setPdfLoadingTask } from "../../redux/actions/documentsAction";

const ViewPdf = ({ is_conversation, file, isFileReady, documentId, pdfWidth, pdfHeight, handleRemoveDocument }) => {
  const documents = useSelector((state) => state.documents.documents);

  const { scrolledIndex, setCurrentPageNumber, scale, setScaleFit, numPages, setNumPages, handleZoomIn, handleZoomOut, nextPage, prevPage, scaleText, pdfFocusRef, goToPage, setZoomLevel, zoomInEnabled, zoomOutEnabled } = usePDFViewer(file && file);

  // const [loadingTask, setLoadingTask] = useState(null);
  const loadingTask = useSelector((state) => state.documents.loadingTask);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   // Return a cleanup function that ensures the worker is destroyed when the component unmounts
  //   return () => {
  //     if (loadingTask) {
  //       try {
  //         console.log('DESTROYING')
  //         loadingTask.destroy(); // This destroys the worker and cleans up
  //       } catch (error) {
  //         console.error("VIEWPDF - Failed to destroy PDF worker:", error);
  //       }
  //     }
  //   };
  // }, [loadingTask]); // Dependency on loadingTask ensures that cleanup runs when it changes

  const handleChange = async (event) => {
    const newValue = event.target.value;
    console.log("Selected New PDF:", newValue);
    if (!newValue) return;

    if (loadingTask) {
      console.log("Destroying existing PDF worker...");
      await loadingTask
        .destroy()
        .then(async () => {
          console.log("Existing PDF worker destroyed.");
          // setLoadingTask(null);
          await dispatch(setPdfLoadingTask(null));
        })
        .catch((error) => console.error("Failed to destroy PDF worker:", error));
    }

    try {
      console.log("Creating new PDF loading task...");
      const newLoadingTask = pdfjsLib.getDocument(newValue.url);
      // setLoadingTask(newLoadingTask);
      await dispatch(setPdfLoadingTask(newLoadingTask));
      newLoadingTask.promise
        .then(async (pdfDocument) => {
          console.log("New PDF loaded:", newValue);
          await dispatch(setActivePdf(newValue));
        })
        .catch((error) => {
          console.error("Failed to load PDF:", error);
        });
    } catch (error) {
      console.error("Error setting up new PDF:", error);
    }
  };

  return (
    <>
      {isFileReady && (
        <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
            {/* <Autocomplete
              value={file}
              clearOnEscape={false}
              onChange={async (event, newValue) => {
                console.log("newValueXX", newValue);
                if (newValue) {
                  try {
                    let loadingTask = pdfjsLib.getDocument(file?.url);
                    if (loadingTask._worker && !loadingTask._worker.destroyed && newValue) {
                      await loadingTask._worker.destroy();
                    }
                  } catch {
                    console.log("error");
                  }
                  setFile(newValue);
                  if (handlePdfFocus) {
                    await handlePdfFocus(newValue.id);
                  }
                }
              }}
              options={documents.flatMap((transaction) => transaction.documents)}
              getOptionLabel={(option) => `${option.name}`}
              renderInput={(params) => <TextField {...params} label="Select Document" variant="outlined" size="small" fullWidth style={{ marginLeft: windowWidth / 220 }} />}
              groupBy={(option) => (option.transactionId ? `Transaction ID: ${option.transactionId.slice(0, 23)}...` : "Unknown Transaction")}
            /> */}

            <FormControl fullWidth size="small" style={{ marginLeft: windowWidth / 220 }}>
              <InputLabel id="document-select-label">Select Document</InputLabel>
              <Select labelId="document-select-label" value={file} onChange={handleChange} label="Select Document">
                {documents
                  .flatMap((transaction) => transaction.documents)
                  .map((doc) => (
                    <MenuItem key={doc.id} value={doc}>
                      {`${doc.name}`} {/* Customize the display as needed */}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={5.5}>
            {scaleText && file && (
              <PDFOptionsBar
                file={file}
                scrolledIndex={scrolledIndex}
                numPages={numPages}
                scaleText={scaleText}
                nextPage={nextPage}
                prevPage={prevPage}
                handleZoomIn={handleZoomIn}
                handleZoomOut={handleZoomOut}
                goToPage={goToPage}
                setZoomLevel={setZoomLevel}
                zoomInEnabled={zoomInEnabled}
                zoomOutEnabled={zoomOutEnabled}
              />
            )}
          </Grid>
          <Grid item xs={12} marginLeft={windowWidth / 220 / 8}>
            {file && <MemoizedVirtualizedPDF is_conversation={is_conversation} ref={pdfFocusRef} file={file} setIndex={setCurrentPageNumber} scale={scale} setScaleFit={setScaleFit} setNumPages={setNumPages} pdfWidth={pdfWidth} pdfHeight={pdfHeight} />}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ViewPdf;
// export default memo(ViewPdf);
