import React from "react";
import { Box, Typography, Button, Grid, List, ListItem, ListItemText, ListItemIcon, Paper, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "../../../utils/colors";
import { OpenCalendar, isMobile, windowHeight } from "../../../utils/utils";

const featureList = [
  {
    number: "1",
    title: "Benchmarking",
    description: "Instantly generate internal benchmarks to enhance the robustness of your decision-making process. Our platform swiftly aligns your data with key performance metrics.",
  },
  {
    number: "2",
    title: "Data Consistency",
    description: "Ensure the integrity of your data with our tool's automated consistency checks. Eliminate the need for manual data reconciliation with contract details.",
  },
  {
    number: "3",
    title: "Market Perspective Analysis",
    description: "Stay ahead of market movements by seamlessly extracting and integrating the sell-side perspective. Our tool enables direct comparison with your strategic views, keeping you aligned with current market dynamics.",
  },
];

const StyledButton = styled(Button)({
  backgroundColor: "white", // Replace with the exact color code from the image
  color: colors.LGREEN, // Text color
  borderRadius: "5px", // Adjust to match the desired border radius from the image
  padding: "10px 30px", // Adjust padding to match the image
  boxShadow: "0 4px 12px 0 rgba(0, 0, 0, 0.2)", // Add shadow to match the image
  "&:hover": {
    // backgroundColor: "#43a047", // Darker shade for the hover state
    // boxShadow can be adjusted for hover state if needed
  },
  textTransform: "none", // Prevent uppercase text
  fontWeight: "bold", // Make font bold
});

const SectionFour = (props) => {
  return (
    <Box sx={{ flexGrow: 1, overflow: "hidden", elevation: 0 }}>
      <Box sx={{ maxWidth: 936, margin: "auto", overflow: "hidden", elevation: 0 }}>
        <Grid sx={{ elevation: 0 }} container wrap="nowrap" spacing={2} p={4}>
          <Grid item xs={12} md={6}>
            <Typography style={{ fontWeight: "bold" }} variant="h3" component="h2" gutterBottom>
              Catalyze Growth Through Process Optimization
            </Typography>
            <Typography style={{ color: colors.PGRAY }} variant="body1" mb={3}>
              Elevate the efficiency of your M&A operations by prioritizing strategic decision-making. Allow our AI to navigate the document landscape, extracting essential information, thereby freeing your team to focus on leveraging their expertise to its fullest potential and driving superior
              business performance.
            </Typography>
            {isMobile() && (
              <>
                {featureList.map((feature) => (
                  <ListItem key={feature.number} style={{ alignItems: "flex-start", padding: 0 }}>
                    <ListItemIcon>
                      {/* Custom icon with number */}
                      <Box
                        sx={{
                          bgcolor: colors.LGREEN,
                          color: "white",
                          borderRadius: "50%",
                          width: 36,
                          height: 36,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "16px",
                          marginTop: "8px",
                        }}
                      >
                        {feature.number} {/* Number is now inside the Box */}
                      </Box>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="h6" style={{}}>
                          {feature.title}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2" style={{ color: colors.PGRAY }}>
                          {feature.description}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </>
            )}
            <StyledButton onClick={OpenCalendar} variant="contained" color="primary" sx={{ marginTop: isMobile() ? windowHeight / 200 : 0, backgroundColor: colors.LGREEN, color: "white", textTransform: "none" }}>
              Book a demo
            </StyledButton>
          </Grid>

          {!isMobile() && (
            <Grid item xs={12} md={6}>
              <List>
                {featureList.map((feature) => (
                  <ListItem key={feature.number} style={{ alignItems: "flex-start", padding: 0 }}>
                    <ListItemIcon>
                      {/* Custom icon with number */}
                      <Box
                        sx={{
                          bgcolor: colors.LGREEN,
                          color: "white",
                          borderRadius: "50%",
                          width: 36,
                          height: 36,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "16px",
                          marginTop: "8px",
                        }}
                      >
                        {feature.number} {/* Number is now inside the Box */}
                      </Box>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="h6" style={{}}>
                          {feature.title}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2" style={{ color: colors.PGRAY }}>
                          {feature.description}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default SectionFour;
