import React from "react";
import { Typography, Container, Paper, Box, Button, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

// Update these paths to the correct locations of your images
import platformImage from "../../../assets/landingpage/pv.png";
import { colors } from "../../../utils/colors";
import { OpenCalendar, isMobile, windowHeight, windowWidth } from "../../../utils/utils";

const StyledButton = styled(Button)({
  backgroundColor: "white", // Replace with the exact color code from the image
  color: colors.LGREEN, // Text color
  borderRadius: "5px", // Adjust to match the desired border radius from the image
  padding: "10px 30px", // Adjust padding to match the image
  boxShadow: "0 4px 12px 0 rgba(0, 0, 0, 0.2)", // Add shadow to match the image
  "&:hover": {
    // backgroundColor: "#43a047", // Darker shade for the hover state
    color: "white",
    // boxShadow can be adjusted for hover state if needed
  },
  textTransform: "none", // Prevent uppercase text
  fontWeight: "bold", // Make font bold
});

const SectionZero = () => {
  return (
    <Container maxWidth="lg" sx={{ marginTop: { xs: 2, md: 10 }, flexGrow: 1, display: "flex", alignItems: "center" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box my={4} textAlign="left">
            <Typography variant="h6" color="white" gutterBottom sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}>
              Announcing
            </Typography>
            {isMobile() ? (
              <Typography color="white" fontWeight={"bold"} sx={{ fontSize: { xs: "3rem" }, variant: "h1" }} gutterBottom>
                DueDil Your M&A Co-pilot
              </Typography>
            ) : (
              <Typography color="white" fontWeight={"bold"} variant="h1" gutterBottom>
                DueDil Your M&A Co-pilot
              </Typography>
            )}

            <Typography variant="body1" color="white" paragraph>
              Empowering Investment Managers and Investment Banks to communicate with their Data Efficiently
            </Typography>
            <StyledButton onClick={OpenCalendar} variant="contained">
              Book a demo
            </StyledButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={0}
            sx={{
              marginTop: isMobile() ? -windowHeight / 18 : 0,
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: { xs: "800px", md: "508px" }, // Adjust the height for mobile
              width: { xs: windowWidth / 1.08, md: "700px" }, // Adjust the height for mobile
              backgroundColor: "transparent",
              backgroundImage: `url(${platformImage})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            {/* If there's supposed to be content over the image, add it here */}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SectionZero;
