import React, { useCallback, useState, useEffect, useRef, memo, forwardRef } from "react";
import { VariableSizeList as List } from "react-window";
import { useWindowWidth, useWindowHeight } from "@wojtekmaj/react-hooks";
import { useInView } from "react-intersection-observer";
import debounce from "lodash.debounce";
import { HORIZONTAL_GUTTER_SIZE_PX, OBSERVER_THRESHOLD_PERCENTAGE, PAGE_HEIGHT, PDF_HEADER_SIZE_PX, PDF_SIDEBAR_SIZE_PX, PDF_WIDTH_PERCENTAGE, VERTICAL_GUTTER_SIZE_PX } from "./pdfDisplayConstants";
// import { DocumentData as PdfDocument } from "~/types/document";
import { Document, Page, pdfjs } from "react-pdf";
import CircularProgress from "@mui/material/CircularProgress";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// import { usePdfFocus } from "~/context/pdf";
// import { multiHighlight } from "~/utils/multi-line-highlight";
import { usePdfFocus } from "./pdfContext";
import { multiHighlight, scrollToHighlight } from "./multi-line-highlight";
import { useSelector } from "react-redux";

// pdfjs options
const pdfjsOptions = pdfjs.GlobalWorkerOptions;
const pdfjsVersion = pdfjs.version;
pdfjsOptions.workerSrc = "//unpkg.com/pdfjs-dist@" + String(pdfjsVersion) + "/legacy/build/pdf.worker.min.js";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjsVersion}/legacy/build/pdf.worker.min.js`;

// Types
const PageRenderer = ({ is_conversation, file, pageNumber, style, scale, listWidth, setPageInView, citationRef }) => {
  const { pdfFocusState } = usePdfFocus();
  const [shouldCenter, setShouldCenter] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(false);

  const { ref: inViewRef, inView } = useInView({
    threshold: OBSERVER_THRESHOLD_PERCENTAGE * Math.min(1 / scale, 1),
  });

  const containerRef = useRef(null);
  // const hasScrolled = useRef(false); // Create a ref to track scrolling
  const setRefs = useCallback(
    (node) => {
      containerRef.current = node;
      inViewRef(node);
      // console.log("node", node);
      // console.log("containerref", containerRef);
    },
    [inViewRef],
  );

  useEffect(() => {
    if (inView) {
      setPageInView(pageNumber);
    }
  }, [inView, pageNumber, setPageInView, inViewRef]);

  useEffect(() => {
    if (pdfFocusState.citation !== citationRef.current.currentCitation) {
      // console.log("Updating citationRef: ", pdfFocusState.citation, citationRef.current.currentCitation);
      citationRef.current.currentCitation = pdfFocusState.citation;
      citationRef.current.scrolled = false;
      citationRef.current.pageNumber = null;
      // console.log("Reset the scroll state");
    }
  }, [pdfFocusState.citation]);

  const hidePageCanvas = useCallback(() => {
    if (containerRef.current) {
      const canvas = containerRef.current.querySelector("canvas");
      if (canvas) canvas.style.visibility = "hidden";
    }
  }, [containerRef]);

  const showPageCanvas = useCallback(() => {
    if (containerRef.current) {
      const canvas = containerRef.current.querySelector("canvas");
      if (canvas) canvas.style.visibility = "visible";
    }
  }, [containerRef]);

  const onPageLoadSuccess = useCallback(() => {
    hidePageCanvas();
  }, [hidePageCanvas]);

  const onPageRenderError = useCallback(() => {
    showPageCanvas();
  }, [showPageCanvas]);

  const documentFocused = pdfFocusState.documentId === file?.id;

  const citation = useSelector((state) => state.documents.citation);
  // const temp = useRef(null);
  const maybeHighlight = useCallback(
    debounce(() => {
      // if (temp.current) return;
      let temp = false;
      // console.log("Running maybeHighlight, current state isHighlighted:", isHighlighted);
      // console.log(temp);
      if ((pdfFocusState.citation, pageNumber)) {
        console.log("Citation:", pdfFocusState.citation, "Page number:", pageNumber);
        temp = multiHighlight(pdfFocusState.citation, pageNumber, 0);

        if (temp && !citationRef.current.pageNumber && !citationRef.current.scrolled) {
          console.log("returning something 1", pageNumber);
          scrollToHighlight(pageNumber);
          citationRef.current.scrolled = true;
          citationRef.current.pageNumber = pageNumber;

          return;
        }
        // console.log("temp", temp.current);
      }
      temp = multiHighlight(pdfFocusState.citation, pageNumber + 2, 0);
      if (temp && !citationRef.current.pageNumber && !citationRef.current.scrolled) {
        // console.log("returning something 2", pageNumber);
        scrollToHighlight(pageNumber + 2);
        citationRef.current.scrolled = true;
        citationRef.current.pageNumber = pageNumber;

        return;
      }
      if (documentFocused && is_conversation && pdfFocusState.citation?.pageNumber === pageNumber + 1 && pdfFocusState.citation?.snippet && !isHighlighted) {
        console.log("Highlighting conversation citation");
        temp = multiHighlight(pdfFocusState.citation?.snippet, pageNumber, 0);
        if (temp && !citationRef.current.pageNumber && !citationRef.current.scrolled) {
          // console.log("returning something 3", pageNumber);
          scrollToHighlight(pageNumber);
          return;
        }
        // setIsHighlighted(true);
        // if (temp) return pageNumber;
        // console.log("temp", temp.current);
      }
    }, 300),
    [pdfFocusState, pageNumber, documentFocused, is_conversation],
    // [pdfFocusState, pageNumber, isHighlighted, documentFocused, is_conversation],
  );
  const onPageRenderSuccess = useCallback(
    (page) => {
      // console.log("onPageReder is running");
      // console.log("our new ref", citationRef);
      // let temp = false;
      showPageCanvas();
      maybeHighlight();

      if (listWidth > page.width) {
        setShouldCenter(true);
      } else {
        setShouldCenter(false);
      }
    },
    [showPageCanvas, listWidth, maybeHighlight],
  );
  // useEffect(() => {
  //   maybeHighlight();
  // }, [pdfFocusState, documentFocused, inView, maybeHighlight]); // Adding pdfFocusState here

  return (
    <div
      key={`${file?.id}-${pageNumber}`}
      ref={setRefs}
      style={{
        ...style,
        padding: "10px",
        backgroundColor: "WhiteSmoke",
        display: `${shouldCenter ? "flex" : ""}`,
        justifyContent: "center",
      }}
    >
      <Page scale={scale} onRenderSuccess={onPageRenderSuccess} onLoadSuccess={onPageLoadSuccess} onRenderError={onPageRenderError} pageIndex={pageNumber} renderAnnotationLayer />
    </div>
  );
};

const VirtualizedPDF = forwardRef(({ is_conversation, file, scale, setIndex, setScaleFit, setNumPages, pdfWidth, pdfHeight }, ref) => {
  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();
  const height = (windowHeight || 0) - PDF_HEADER_SIZE_PX - pdfHeight;
  const newWidthPx = PDF_WIDTH_PERCENTAGE * 0.01 * (windowWidth || 0) - PDF_SIDEBAR_SIZE_PX - HORIZONTAL_GUTTER_SIZE_PX - pdfWidth;
  const citationRef = useRef({ currentCitation: null, scrolled: false, pageNumber: null });

  const [pdf, setPdf] = useState(null);
  const listRef = useRef(null);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [scale]);

  function onDocumentLoadSuccess(nextPdf) {
    setPdf(nextPdf);
  }
  function getPageHeight() {
    const actualHeight = (PAGE_HEIGHT + VERTICAL_GUTTER_SIZE_PX) * scale;
    return actualHeight;
  }

  useEffect(() => {
    if (!pdf) {
      return;
    }
    async function loadFirstPage() {
      if (pdf) {
        await pdf.getPage(1).then((page) => {
          const pageViewport = page.getViewport({ scale: 1 });
          const pageWidth = pageViewport.width;
          const computedScaleFit = newWidthPx / pageWidth;
          setScaleFit(computedScaleFit);
        });
      }
    }
    loadFirstPage().catch((e) => console.log("page load error", e));
    setNumPages(pdf.numPages);
  }, [pdf, setNumPages, setScaleFit, newWidthPx]);

  React.useImperativeHandle(ref, () => ({
    scrollToPage: (page) => {
      onItemClick({ pageNumber: page });
    },
  }));

  const onItemClick = ({ pageNumber: itemPageNumber }) => {
    const fixedPosition = itemPageNumber * (PAGE_HEIGHT + VERTICAL_GUTTER_SIZE_PX) * scale;
    if (listRef.current) {
      listRef.current.scrollTo(fixedPosition);
    }
  };

  const loadingDiv = () => {
    return (
      <div className={`flex h-[80vh] w-[577px] items-center justify-center`}>
        {" "}
        <CircularProgress size={48} thickness={4} />
      </div>
    );
  };

  return (
    <div className={`relative h-[calc(100vh-160)] w-full border-gray-pdf bg-gray-pdf`}>
      <Document key={file.url} onItemClick={onItemClick} file={file.url} onLoadSuccess={onDocumentLoadSuccess} loading={loadingDiv}>
        {pdf ? (
          <List ref={listRef} width={newWidthPx + HORIZONTAL_GUTTER_SIZE_PX} height={height} itemCount={pdf.numPages} itemSize={getPageHeight} estimatedItemSize={(PAGE_HEIGHT + VERTICAL_GUTTER_SIZE_PX) * scale}>
            {({ index, style }) => <PageRenderer is_conversation={is_conversation} file={file} key={`page-${index}`} pageNumber={index} style={style} scale={scale} listWidth={newWidthPx} setPageInView={setIndex} citationRef={citationRef} />}
          </List>
        ) : null}
      </Document>
    </div>
  );
});

const MemoizedVirtualizedPDF = memo(VirtualizedPDF);

MemoizedVirtualizedPDF.displayName = "VirtualizedPDF";

export default MemoizedVirtualizedPDF;
