import React from "react";
import { Box, Typography, Container, Grid } from "@mui/material";
import myImage from "../../../assets/landingpage/s31.png"; // adjust the path as necessary
import { colors } from "../../../utils/colors";

const SectionThree = React.forwardRef((props, ref) => {
  return (
    <Container ref={ref} maxWidth="lg">
      <Box my={4} py={4} textAlign="center">
        <Typography style={{ fontWeight: "bold" }} variant="h3" component="h1" gutterBottom>
          Tailored Platform Experience
        </Typography>
        <Typography variant="h4" color="textSecondary">
          Platform Synopsis
        </Typography>
        <Typography style={{ color: colors.PGRAY }} variant="subtitle1" mt={2}>
          Seamlessly integrate your cloud storage, data rooms, or local folders for an instant dive into transaction analysis. Garner insights that resonate with your fund's strategic blueprint, and optimize time by harnessing AI to sift through data, pinpointing relevant information swiftly. Focus
          on strategic decision-making, while our AI handles the groundwork.
        </Typography>
      </Box>
      <Grid item xs={12} md={6}>
        <img src={myImage} alt="Landing Page" style={{ width: "100%", height: "auto" }} />
      </Grid>
    </Container>
  );
});

export default SectionThree;
