import React from "react";
import { Box, Grid, Typography, Paper, useTheme, Button } from "@mui/material";
import { styled } from "@mui/system";

// Replace these with your actual import paths
import icon1 from "../../../assets/landingpage/s11.png";
import icon2 from "../../../assets/landingpage/s12.png";
import icon3 from "../../../assets/landingpage/s13.png";
import icon4 from "../../../assets/landingpage/s14.png";
import { colors } from "../../../utils/colors";
import { OpenCalendar, isMobile, windowHeight, windowWidth } from "../../../utils/utils";

const StyledButton = styled(Button)({
  backgroundColor: "white", // Replace with the exact color code from the image
  color: colors.LGREEN, // Text color
  borderRadius: "5px", // Adjust to match the desired border radius from the image
  padding: "10px 30px", // Adjust padding to match the image
  boxShadow: "0 4px 12px 0 rgba(0, 0, 0, 0.2)", // Add shadow to match the image
  textTransform: "none", // Prevent uppercase text
  fontWeight: "bold", // Make font bold
});

const FeatureIcon = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  //   width: theme.spacing(16), // Size increased from 12 to 16
  //   height: theme.spacing(12), // Size increased from 12 to 16
  // borderRadius: "50%",
  margin: "auto",
  marginBottom: theme.spacing(2),
  // If you have specific colors for the icons background, set them here
  backgroundColor: "green", // No background color for the icon
}));

const FeatureGrid = styled(Grid)(({ theme }) => ({
  // If you need additional styling for the grid, set them here
}));

const FeatureItem = ({ icon, title, description }) => {
  const theme = useTheme();
  return (
    <Grid item xs={12} sm={6} md={6}>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        {" "}
        {/* Align items to the left */}
        <FeatureIcon elevation={0} sx={{ backgroundColor: "transparent", margin: "0" }}>
          {" "}
          {/* No background color */}
          <Box component="img" src={icon} alt={title} sx={{ width: theme.spacing(16), height: theme.spacing(16) }} />
        </FeatureIcon>
        <Typography variant="h6" component="h3" gutterBottom sx={{ mt: 2, mb: 1 }}>
          {title}
        </Typography>
        <Box width="40px" height="4px" bgcolor={colors.LGREEN} mb={2} /> {/* Underline effect */}
        <Typography style={{ color: colors.PGRAY }} variant="body2">
          {description}
        </Typography>
      </Box>
    </Grid>
  );
};

const SectionOne = React.forwardRef((props, ref) => {
  const theme = useTheme();

  return (
    <Box ref={ref} sx={{ flexGrow: 1, padding: theme.spacing(4) }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box width="40px" height="4px" bgcolor={colors.LGREEN} mb={2} /> {/* Underline effect */}
          <Typography variant="h3" component="h2" fontWeight="bold" gutterBottom>
            Optimize the Transaction Process with DueDil
          </Typography>
          <Typography color="textSecondary">
            Revolutionize Your M&A Strategies with DueDil: Dive into a world where data becomes your strategic ally, thanks to DueDil's cutting-edge Platform. Navigate the complex financial landscapes with unprecedented ease and connect the dots with minimal effort—unlock the full potential of every
            transaction and elevate your value creation to new heights. Get ready to lead the charge in the M&A frontier with DueDil by your side.
          </Typography>
          {/* <Typography sx={{ cursor: "pointer", color: colors.LGREEN, display: "inline-flex", alignItems: "center", mt: 2 }}>Learn About Our Success</Typography> */}
          <StyledButton onClick={OpenCalendar} variant="contained" sx={{ backgroundColor: colors.LGREEN, color: "white", marginLeft: isMobile() ? windowWidth / 18 : 0, textTransform: "none", marginTop: isMobile() ? windowHeight / 320 : windowHeight / 500 }}>
            Book a demo
          </StyledButton>
        </Grid>
        <FeatureGrid container item xs={12} md={6} spacing={2}>
          <FeatureItem icon={icon1} title="Data Management Efficiency" description="Streamline the organization of complex financial datasets with unparalleled efficiency and precision, ensuring data integrity and accessibility for strategic analysis and decision-making." />
          <FeatureItem icon={icon2} title="Query Optimization" description="Leverage advanced algorithmic models for rapid execution of financial queries, ensuring accurate and comprehensive data analysis in real-time." />
          <FeatureItem icon={icon3} title="Specialized Financial Platform" description="Tailored by M&A experts, this platform is the confluence of industry knowledge and financial acumen, providing bespoke tools for finance professionals." />
          <FeatureItem icon={icon4} title="Data Security Insurance" description="Your sensitive financial information is safeguarded with robust encryption and state-of-the-art security protocols, eliminating unauthorized access and ensuring regulatory compliance." />
        </FeatureGrid>
      </Grid>
    </Box>
  );
});

export default SectionOne;
