import React, { useState } from "react";
import { Box, Grid, Typography, TextField, Button, Snackbar, CircularProgress } from "@mui/material";
import { colors } from "../../../utils/colors";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { addToNewsletter } from "../../../redux/actions/authAction";
import { windowWidth } from "../../../utils/utils";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const dispatch = useDispatch();
  const handleSubmit = async () => {
    setIsLoading(true);
    if (isValidEmail(email)) {
      // Here you would typically make an API request to your backend to handle the email sending
      // Example: axios.post('/api/send-email', { email, date: new Date() })
      const message = await dispatch(addToNewsletter(email));
      console.log("message", message);

      // Assuming the email was sent successfully
      enqueueSnackbar(message.message, { variant: "success" });
      setEmail("");
      setIsLoading(false);
    } else {
      setIsLoading(false);
      alert("Please enter a valid email.");
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: "#333", color: "white", padding: "20px 40px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>
          DueDil
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Empowering Finance Managers and Investment Banks to Organize Data Efficiently
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* Placeholder for future content */}
        </Grid>
        <Grid item xs={12} sm={4} sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ mr: 2 }}>
            <Typography variant="h6" gutterBottom>
              Sign up to our newsletter!
            </Typography>
            <TextField variant="outlined" placeholder="Your email" size="small" sx={{ backgroundColor: "white", borderRadius: "4px" }} InputProps={{ style: { color: "black" } }} value={email} onChange={(e) => setEmail(e.target.value)} />
          </Box>
          <Button
            variant="contained"
            sx={{ textTransform: "none", backgroundColor: colors.LGREEN }}
            onClick={handleSubmit}
            disabled={isLoading} // Disable the button when loading
          >
            {isLoading && <CircularProgress style={{marginRight: windowWidth/100}} size={18} />} Subscribe
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
